export class NavigatorHelper {
  public static openNewWindow(url?: string | URL) {
    /**
     * 15.11.2022 Safari блокирует любой вызов window.open() внутри асинхронного вызова.
     * SetTimeout нужен для выполнения в основном потоке, а не в асинхронном.
     */
    setTimeout(() => {
      window.open(url, '_blank');
    });
  }
  /**
   * TODO: Найти замену navigateTo вместо goToUrl
   */
  public static goToUrl(url: string, _blank = false): void {
    window.opener = null;
    _blank ? window.open(url, '_blank')?.focus() : navigateTo(url);
  }
}
